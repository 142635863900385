import React, { useEffect } from "react";
import { PostHogProvider } from "posthog-js/react";
import { posthog } from "posthog-js";

export const RootLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    posthog.init("phc_j6fTwEMxoWfk3KLYQhhGkm6QpQWCtBXg7fXMxQgaSAd", {
      api_host: "https://us.i.posthog.com",
    });
  }, []);
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};
